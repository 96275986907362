import styled, { css } from "styled-components"
import { InputLabel, Typography } from "@mui/material"
import TextField from "@mui/material/TextField"
import CustomLink from "../custom-link/custom-link.component"
import CancelIcon from "@mui/icons-material/Cancel"

const textInputStyle = css`
  color: ${({ theme }) => theme.palette.text.content};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(20)};
`

// export const Wrapper = styled.div`
//   max-width: 400px;
//   max-height: 640px;
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   background-color: white;
//   padding: 2em 0;
//   border-radius: 5px;
// `

// export const Title = styled.h2`
//   color: #09090b;
//   font-family: ${({ theme }) => theme.fonts.secondary};
//   font-size: 32px;
//   font-weight: 500;
//   letter-spacing: 0;
//   line-height: 36px;
// `

// export const Description = styled(Typography)`
//   color: ${({ theme }) => theme.palette.text.content};
//   font-family: ${({ theme }) => theme.fonts.secondary};
//   font-size: ${({ theme }) => theme.typography.pxToRem(16)};
//   letter-spacing: 0;
//   line-height: 24px;
//   padding: 1em 0;
// `

export const CustomForm = styled.form`
  overflow-y: scroll;
`

export const CustomField = styled(TextField)`
  width: 100%;

  .MuiInputBase-root {
    color: #566171;
    border-bottom: 1px solid ${({ theme }) => theme.palette.tertiary.main};
  }
  .MuiInputLabel-root {
    ${textInputStyle}
  }
`

export const CustomInput = styled(InputLabel)`
  ${textInputStyle}
`

export const TermsDiv = styled.div`
  margin: 1em 0;
`

export const Link = styled(CustomLink)`
  text-decoration: underline;
  color: black;
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
`

export const TopWrapper = styled.div`
  position: relative;
  height: 100%;
`

// export const CloseIcon = styled(CancelIcon)`
//   position: absolute;
//   top: -51px;
//   right: -19px;
//   width: 40px;
//   height: 40px;
//   cursor: pointer;
//   fill: black;
// `
