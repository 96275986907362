import styled from "styled-components"
import CustomDialog from "../custom-dialog/custom-dialog.component"
import Typography from "@mui/material/Typography"

export const Wrapper = styled.div`
  text-align: center;
  padding: 1em 0;
  h3 {
    font-size: ${({ theme }) => theme.typography.pxToRem(26)};
  }
  p {
    font-size: 1rem;
    margin-top: 0;
  }
`
