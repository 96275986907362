import styled from "styled-components"
import CustomBgImage from "../custom-bg-image/custom-bg-image.component"
import { Container, Dialog } from "@mui/material"
import CustomButton from "../custom-button/custom-button.component"
import SectionWrapper from "../section-wrapper/section-wrapper.component"

// export const GetStartedBanner = styled.div`
//   background-color: ${props => props.color};
//   padding: 2em 0;
//
//   .no-d-block {
//     width: auto;
//   }
//   .MuiContainer-root {
//     ${({ theme }) => theme.breakpoints.down("sm")} {
//       padding: 1rem;
//     }
//   }
// `
// export const Wrapper = styled(Container)`
//   //padding: 0;
// `
// export const ImageBanner = styled(CustomBgImage)`
//   padding: 2em 1em;
//   text-align: center;
//   border-radius: 20px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   ${({ theme }) => theme.breakpoints.up("md")} {
//     min-height: 330px;
//   }
// `
//
export const BannerTitle = styled.h2`
  color: black;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(44)};
  font-weight: 500;
  letter-spacing: 0;
  line-height: 48px;
  ${({ variation }) =>
    variation === "partners" &&
    `margin-bottom: 1em;
    `}

  ${({ theme }) => theme.breakpoints.down("md")} {
    line-height: 48px;
  }

  &.noDescription {
    margin-bottom: 1em;
  }
`

export const BannerContent = styled.p`
  color: black;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(18)};
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  margin-bottom: 1.5em;
`

export const CustomDialog = styled(Dialog)`
  .MuiPaper-root {
    overflow-y: unset;
  }
`
//
export const Button = styled(CustomButton)`
  text-transform: none;
`

export const Wrapper = styled.div`
  background-color: ${props => props.color};
  padding: 2em 0;

  .no-d-block {
    width: auto;
  }
  .MuiContainer-root {
    ${({ theme }) => theme.breakpoints.down("sm")} {
      padding: 1rem;
    }
  }
`

export const VideoWrapper = styled.div`
  position: relative;
  padding: 2em 1em;
  text-align: center;
  border-radius: 20px;
  min-height: 300px;
  ${({ theme }) => theme.breakpoints.up("md")} {
    min-height: 330px;
  }
`

export const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  object-fit: cover !important;
  border-radius: 20px;
  line-height: 0;
`

export const InfoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
`

export const ContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
