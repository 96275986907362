import React, { useEffect } from "react"
import * as S from "./thank-you-message.styles"
import PropTypes from "prop-types"
import { isBrowser } from "../../utils"
import parse from "html-react-parser"

const ThankYouMessage = ({ message }) => {
  useEffect(() => {
    if (isBrowser()) {
      document.getElementById("formTopWrapper")?.remove()
    }
  }, [])

  return <S.Wrapper>{parse(message)}</S.Wrapper>
}

ThankYouMessage.propTypes = {
  message: PropTypes.string.isRequired,
}
export default ThankYouMessage
