import React, { useState } from "react"
import * as S from "./footer.styles"
import {
  Container,
  DialogContent,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import Logo from "../../images/ready-robotics-logo.png"
import { ItemsWrapper } from "./footer.styles"
import FooterBanner from "../footer-banner/footer-banner.component"
import { Modal } from "@mui/material"
import NewsletterForm from "../newsletter-form/newsletter-form.component"
import CustomDialog from "../custom-dialog/custom-dialog.component"
import { gtagConversionNewsletterClick } from "../../gtag-utils"
import { useLocation } from "@reach/router"
import MobileFooter from "../mobile-footer/mobile-footer.component"

const Footer = ({
  className,
  titlePage,
  hideFooterBanner,
  footerBanner,
  hideDescription,
  toContact,
}) => {
  const location = useLocation()
  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(true)
    gtagConversionNewsletterClick(location.href)
  }
  const handleClose = () => setOpen(false)

  const theme = useTheme()
  const isLG = useMediaQuery(theme.breakpoints.down("lg"))

  return (
    <>
      <S.Wrapper className={className}>
        {!hideFooterBanner && (
          <FooterBanner
            {...footerBanner}
            hideDescription={hideDescription}
            titlePage={titlePage}
            toContact={toContact}
          />
        )}
        {!isLG ? (
          <Container maxWidth="xl">
            <S.TopWrapper>
              <Grid container>
                <Grid item xs={12} lg={9}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} lg>
                      <ItemsWrapper>
                        <S.CustomLinks className="title">
                          Software
                        </S.CustomLinks>
                        <S.CustomLinks href="/software/buy-forge-os/">
                          Buy Forge/OS
                        </S.CustomLinks>
                        <S.CustomLinks href="/software/forge-os/">
                          Forge/OS
                        </S.CustomLinks>
                        <S.CustomLinks href="/software/productivity-apps/">
                          Forge Productivity Apps
                        </S.CustomLinks>
                        <S.CustomLinks href="/software/forge-os-for-developers/">
                          Forge SDK
                        </S.CustomLinks>
                      </ItemsWrapper>
                    </Grid>
                    <Grid item xs={6} sm={6} lg>
                      <ItemsWrapper>
                        <S.CustomLinks className="title">Partner</S.CustomLinks>
                        <S.CustomLinks href="/partner/robot-oems/">
                          Robot OEMs
                        </S.CustomLinks>
                        <S.CustomLinks href="/partner/integrators-machine-builders/">
                          Integrators
                        </S.CustomLinks>
                        <S.CustomLinks href="/partner/distributors/">
                          Distributors
                        </S.CustomLinks>
                        <S.CustomLinks href="/partner/hardware-oems/">
                          Hardware OEMs
                        </S.CustomLinks>
                      </ItemsWrapper>
                    </Grid>
                    <Grid item xs={6} sm={6} lg>
                      <ItemsWrapper>
                        <S.CustomLinks className="title">
                          Resources
                        </S.CustomLinks>
                        <S.CustomLinks href="https://academy.ready-robotics.com/">
                          READY Academy
                        </S.CustomLinks>
                        <S.CustomLinks href="https://ecosystem.ready-robotics.com/">
                          READY Ecosystem
                        </S.CustomLinks>
                        <S.CustomLinks href="/resources/case-studies//">
                          Case Studies
                        </S.CustomLinks>
                        <S.CustomLinks href="/resources/whitepapers/">
                          Whitepapers
                        </S.CustomLinks>
                        <S.CustomLinks href="/resources/articles/">
                          Articles
                        </S.CustomLinks>
                        <S.CustomLinks href="/resources/ready-content/">
                          READY Content
                        </S.CustomLinks>
                        <S.CustomLinks
                          target="_blank"
                          href="https://support.ready-robotics.com/"
                        >
                          Support
                        </S.CustomLinks>
                      </ItemsWrapper>
                    </Grid>
                    <Grid item xs={6} sm={6} lg>
                      <ItemsWrapper>
                        <S.CustomLinks className="title">About</S.CustomLinks>
                        <S.CustomLinks href="/contact/">Contact</S.CustomLinks>
                        <S.CustomLinks href="/about/">Leadership</S.CustomLinks>
                        <S.CustomLinks href="/about/locations/">
                          Facilities
                        </S.CustomLinks>
                        <S.CustomLinks href="/about/careers/">
                          Careers
                        </S.CustomLinks>
                      </ItemsWrapper>
                    </Grid>
                    <Grid item xs={6} sm={6} lg>
                      <ItemsWrapper>
                        <S.CustomLinks className="title">
                          Follow Us
                        </S.CustomLinks>
                        <S.ExternalLink
                          href="https://www.linkedin.com/company/ready-robotics"
                          target="_blank"
                        >
                          LinkedIn
                        </S.ExternalLink>
                        <S.ExternalLink
                          href="https://www.facebook.com/READY-Robotics-509382042790152"
                          target="_blank"
                        >
                          Facebook
                        </S.ExternalLink>
                        <S.ExternalLink
                          href="https://twitter.com/readyrobotics"
                          target="_blank"
                        >
                          Twitter
                        </S.ExternalLink>
                        <S.ExternalLink
                          href="https://www.instagram.com/readyrobotics"
                          target="_blank"
                        >
                          Instagram
                        </S.ExternalLink>

                        <S.ExternalLink
                          href="https://www.youtube.com/channel/UCOUlHG_OMWAumLEN2DMCTww/videos"
                          target="_blank"
                        >
                          YouTube
                        </S.ExternalLink>
                      </ItemsWrapper>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={3}>
                  <CustomDialog
                    open={open}
                    handleClose={handleClose}
                    title="Subscribe to our Newsletter"
                    description="Stay informed on the cutting edge of automation, robotics,
                      manufacturing, and READY!"
                  >
                    <NewsletterForm handleClose={handleClose} />
                  </CustomDialog>
                  <S.FormWrapper>
                    <S.NewsLetterTitle>
                      Subscribe to our Newsletter
                    </S.NewsLetterTitle>
                    <S.NewsletterContent>
                      <p>
                        Stay informed on the cutting edge of automation,
                        robotics, manufacturing, and READY!
                      </p>
                      <S.ButtonContainer>
                        <S.Button onClick={handleOpen} className="gray">
                          Newsletter Signup
                        </S.Button>
                      </S.ButtonContainer>
                    </S.NewsletterContent>
                  </S.FormWrapper>
                </Grid>
              </Grid>
            </S.TopWrapper>
            <S.Line />
            <S.BottomWrapper>
              <S.LeftWrapper>
                <S.WhiteLogo />
                <S.LeftText>
                  <span>
                    Copyright © {new Date().getFullYear()} READY ROBOTICS. ALL
                    RIGHTS RESERVED
                  </span>
                </S.LeftText>
              </S.LeftWrapper>
              <a href="https://aftershock.agency/" target="_blank">
                <S.PoweredByIcon />
              </a>
            </S.BottomWrapper>
          </Container>
        ) : (
          <MobileFooter />
        )}
      </S.Wrapper>
    </>
  )
}

export default Footer
