import styled from "styled-components"
import IconButton from "@mui/material/IconButton"
import Box from "@mui/material/Box"
import CustomButton from "../../custom-button/custom-button.component"

export const ItemsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: white;
  padding: 2em 1.5em 2em;
  //height: calc(100vh - ${({ theme }) => theme.navHeight}px);
  z-index: 9999;
  width: 100%;
  position: fixed;
  ${({ bannerHeight }) =>
    bannerHeight ? `top: ${66 + bannerHeight}px;` : `top: 66px;`};
  left: 0;
  right: 0;
  bottom: 0;

  .search {
    margin-bottom: 1em;
  }
`

export const InnerWrapper = styled.div``

export const MenuIcon = styled(IconButton)`
  z-index: 1;
`

export const ButtonWrapper = styled.div`
  margin-bottom: 1em;
  text-align: center;
`

export const Button = styled(CustomButton)`
  width: 100%;
  background-color: black;
  text-transform: none;
  font-size: 1rem;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0.02em;
  line-height: 120%;
  min-height: 45px;
`
