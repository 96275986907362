import styled from "styled-components"

import CustomImage from "../../custom-image/custom-image.component"
import { Grid } from "@mui/material"
import CustomLink from "../../custom-link/custom-link.component"
import AndroidIcon from "@mui/icons-material/Android"

export const ItemsContainer = styled.div`
  background-color: #ffffff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  width: 210px;
  color: #000000;
  position: absolute;
  top: 24px;
  left: -70px;
  cursor: default;

  a {
    text-align: left;
    font-weight: 500;
    font-size: 1em;
    display: block;
    padding: 1em;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 90px;
    width: 25px;
    height: 25px;
    background-color: #ffffff;
    color: #000000;
    transform: translateY(-50%) rotate(45deg);
    z-index: 0;
    box-shadow: -1px -1px 0 0 rgba(0, 0, 0, 0.1);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-left: 1px solid rgba(0, 0, 0, 0.1);

  }

,
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1.5em;
`

export const Title = styled.h2`
  font-weight: 700;
  font-size: ${({ theme }) => theme.typography.pxToRem(48)};
  line-height: ${({ theme }) => theme.typography.pxToRem(52)};
`

export const Link = styled(CustomLink)`
  p {
    //transition: all 0.3s ease;
    position: relative;
    margin: 0;
  }

  > div {
    transition: all 0.3s ease;

    // > div {
    //   background: url(${({ whiteIcon = "" }) => whiteIcon}) no-repeat center;
    //   background-size: cover;
    // }
  }

  &:hover {
    > div {
      opacity: 1;
      color: ${({ theme }) => theme.palette.secondary.main};

      // > div {
      //   background: url(${({ blueIcon = "" }) => blueIcon}) no-repeat center;
      //   background-size: cover;
      // }
    }
  }
`

export const Left = styled(Grid)`
  height: 100%;
`

export const Right = styled(Grid)`
  margin-top: 4em;
  margin-bottom: 4em;

  h2 {
    margin-bottom: 1em;
    padding-left: 1.25em;
    padding-right: 1.25em;
  }

  p {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    width: 100%;
    font-size: ${({ theme }) => theme.typography.pxToRem(20)};
    line-height: ${({ theme }) => theme.typography.pxToRem(24)};
  }

  a {
    display: flex;
    align-items: center;
    padding-left: 4em;
    padding-right: 4em;
  }
`

export const Image = styled(CustomImage)`
  height: 100%;
  width: 100%;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-width: unset !important;
  }
`

// export const Icon = styled.div`
//   width: 36px;
//   height: 36px;
// `

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.5em;
  transition: all 0.3s ease;
`
