import React, { useEffect } from "react"
import * as S from "./black-nav.styles"
import { isBrowser } from "../../../utils"
import { useTheme } from "@mui/system"

const BlackNav = ({ items }) => {
  const theme = useTheme()
  useEffect(() => {
    if (isBrowser()) {
      const main = document.getElementById("mainWrapper")
      const bannerHeight =
        document.getElementById("secondaryTopBanner")?.clientHeight
      main.style.paddingTop = `${theme.navHeight + bannerHeight - 7}px`
    }
    return () => {
      const main = document.getElementById("mainWrapper")
      main.style.paddingTop = `${theme.navHeight}px`
    }
  }, [])

  return (
    <S.Wrapper id="secondaryTopBanner">
      <S.NavItem
        activeClass="active"
        to="Task Canvas"
        spy={true}
        smooth={true}
        offset={-100}
      >
        Task Canvas
      </S.NavItem>
      <S.NavItem
        activeClass="active"
        to="Device Control"
        spy={true}
        smooth={true}
        offset={-100}
      >
        Device Control
      </S.NavItem>
      {/*<S.NavItem*/}
      {/*  activeClass="active"*/}
      {/*  to="I/O Process"*/}
      {/*  spy={true}*/}
      {/*  smooth={true}*/}
      {/*  offset={-110}*/}
      {/*>*/}
      {/*  I/O Process*/}
      {/*</S.NavItem>*/}
      <S.NavItem
        activeClass="active"
        to="Device Configuration"
        spy={true}
        smooth={true}
        offset={-110}
      >
        Device Configuration
      </S.NavItem>

      {/*<S.NavItem*/}
      {/*  activeClass="active"*/}
      {/*  to="Marketplace"*/}
      {/*  spy={true}*/}
      {/*  smooth={true}*/}
      {/*  offset={-100}*/}
      {/*>*/}
      {/*  Marketplace*/}
      {/*</S.NavItem>*/}
    </S.Wrapper>
  )
}

export default BlackNav
