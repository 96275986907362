import React from "react"
import * as S from "./header-mobile.styles.jsx"
import HeaderMobileItem from "./header-mobile-item/header-mobile-item.component"
import Fade from "@mui/material/Fade"
import { cleanBlankCharacter } from "../../../utils"
import CustomLink from "../../custom-link/custom-link.component"
import { use100vh } from "react-div-100vh"
import { useTheme } from "@mui/material"

const HeaderMobile = ({
  items,
  children,
  isActiveMenu,
  handleClose,
  bannerHeight,
}) => {
  const height = use100vh()
  const theme = useTheme()
  const wrapperHeight = height
    ? `${height - theme.navHeight - bannerHeight}px`
    : `calc(100vh - ${theme.navHeight - bannerHeight}px)`
  return (
    <Fade in={isActiveMenu} mountOnEnter unmountOnExit>
      <S.ItemsWrapper
        id="mobileItemsWrapper"
        bannerHeight={bannerHeight}
        style={{ height: wrapperHeight }}
      >
        <S.InnerWrapper>
          {children}
          {items.map((item, index) => (
            <HeaderMobileItem
              handleClose={handleClose}
              item={item}
              key={`${item?.sectionTitle}-${index}`}
            />
          ))}
        </S.InnerWrapper>
        <S.ButtonWrapper>
          <CustomLink url="/forge-os-experience/">
            <S.Button fullWidth className="blue">
              {cleanBlankCharacter("Experience Forge/OS")}
            </S.Button>
          </CustomLink>
        </S.ButtonWrapper>
      </S.ItemsWrapper>
    </Fade>
  )
}
export default HeaderMobile
