import React, { useState } from "react"
import * as S from "./mobile-footer.styles"
import Logo from "../../images/ready-robotics-logo.png"
import { Container } from "@mui/material"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import CustomDialog from "../custom-dialog/custom-dialog.component"
import NewsletterForm from "../newsletter-form/newsletter-form.component"
import { gtagConversionNewsletterClick } from "../../gtag-utils"
import { useLocation } from "@reach/router"

const MobileFooter = ({}) => {
  const [expanded, setExpanded] = useState(false)
  const location = useLocation()
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(true)
    gtagConversionNewsletterClick(location.href)
  }
  const handleClose = () => setOpen(false)

  return (
    <S.Wrapper>
      <Container>
        <S.Logo alt="Ready Robotics" src={Logo} />
        <S.AccordionWrapper>
          <S.CustomAccordion
            disableGutters
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<S.ExpandIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{ padding: "0" }}
            >
              <S.AccordionTitle>Software</S.AccordionTitle>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "0 0 16px" }}>
              <S.LinksWrapper>
                <S.MobileLink url="/software/buy-forge-os/">
                  Buy Forge/OS
                </S.MobileLink>
                <S.MobileLink url="/software/forge-os/">Forge/OS</S.MobileLink>
                <S.MobileLink url="/software/productivity-apps/">
                  Forge Productivity Apps
                </S.MobileLink>
                <S.MobileLink url="/software/forge-os-for-developers/">
                  Forge SDK
                </S.MobileLink>
              </S.LinksWrapper>
            </AccordionDetails>
          </S.CustomAccordion>
          <S.CustomAccordion
            disableGutters
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<S.ExpandIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
              sx={{ padding: "0" }}
            >
              <S.AccordionTitle>Partner</S.AccordionTitle>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "0 0 16px" }}>
              <S.LinksWrapper>
                <S.MobileLink url="/partner/robot-oems/">
                  Robots OEMs
                </S.MobileLink>
                <S.MobileLink url="/partner/integrators-machine-builders/">
                  Integrators
                </S.MobileLink>
                <S.MobileLink url="/partner/distributors/">
                  Distributors
                </S.MobileLink>
                <S.MobileLink url="/partner/hardware-oems/">
                  Hardware OEMs
                </S.MobileLink>
              </S.LinksWrapper>
            </AccordionDetails>
          </S.CustomAccordion>
          <S.CustomAccordion
            disableGutters
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              expandIcon={<S.ExpandIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
              sx={{ padding: "0" }}
            >
              <S.AccordionTitle>Resources</S.AccordionTitle>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "0 0 16px" }}>
              <S.LinksWrapper>
                <S.MobileLink url="https://academy.ready-robotics.com/">
                  READY Academy
                </S.MobileLink>
                <S.MobileLink href="https://ecosystem.ready-robotics.com/">
                  READY Ecosystem
                </S.MobileLink>
                <S.MobileLink url="/resources/case-studies/">
                  Case Studies
                </S.MobileLink>
                <S.MobileLink url="/resources/whitepapers/">
                  Whitepapers
                </S.MobileLink>
                <S.MobileLink url="/resources/articles/">Articles</S.MobileLink>
                <S.MobileLink url="/resources/ready-content/">
                  Ready Content
                </S.MobileLink>
                <S.MobileLink url="https://support.ready-robotics.com/">
                  Support
                </S.MobileLink>
              </S.LinksWrapper>
            </AccordionDetails>
          </S.CustomAccordion>
          <S.CustomAccordion
            disableGutters
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary
              expandIcon={<S.ExpandIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
              sx={{ padding: "0" }}
            >
              <S.AccordionTitle>About</S.AccordionTitle>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "0 0 16px" }}>
              <S.LinksWrapper>
                <S.MobileLink url="/contact/">Contact</S.MobileLink>
                <S.MobileLink url="/about/">Leadership</S.MobileLink>
                <S.MobileLink url="/about/locations/">Facilities</S.MobileLink>
                <S.MobileLink url="/about/careers/">Careers</S.MobileLink>
              </S.LinksWrapper>
            </AccordionDetails>
          </S.CustomAccordion>
        </S.AccordionWrapper>
        <S.SocialWrapper>
          <S.AccordionTitle>Follow Us</S.AccordionTitle>
          <S.LinksWrapper>
            <S.ExternalLink
              href="https://www.linkedin.com/company/ready-robotics"
              target="_blank"
            >
              LinkedIn
            </S.ExternalLink>
            <S.ExternalLink
              href="https://www.facebook.com/READY-Robotics-509382042790152"
              target="_blank"
            >
              Facebook
            </S.ExternalLink>
            <S.ExternalLink
              href="https://twitter.com/readyrobotics"
              target="_blank"
            >
              Twitter
            </S.ExternalLink>
            <S.ExternalLink
              href="https://www.instagram.com/readyrobotics"
              target="_blank"
            >
              Instagram
            </S.ExternalLink>

            <S.ExternalLink
              href="https://www.youtube.com/channel/UCOUlHG_OMWAumLEN2DMCTww/videos"
              target="_blank"
            >
              YouTube
            </S.ExternalLink>
          </S.LinksWrapper>
        </S.SocialWrapper>
        <S.FormWrapper>
          <S.NewsLetterTitle>Subscribe to our Newsletter</S.NewsLetterTitle>
          <S.NewsletterContent>
            <p>
              Stay informed on the cutting edge of automation, robotics,
              manufacturing, and READY!
            </p>
            <S.ButtonContainer>
              <S.Button onClick={handleOpen} className="gray">
                Newsletter Signup
              </S.Button>
            </S.ButtonContainer>
          </S.NewsletterContent>
          <CustomDialog
            open={open}
            handleClose={handleClose}
            title="Subscribe to our Newsletter"
            description="Stay informed on the cutting edge of automation, robotics,
                      manufacturing, and READY!"
          >
            <NewsletterForm handleClose={handleClose} />
          </CustomDialog>
        </S.FormWrapper>
      </Container>
      <S.Line />
      <Container maxWidth="xl" sx={{ padding: "0" }}>
        <S.BottomWrapper>
          <S.TextWrapper>
            <S.LeftText>
              <span>
                Copyright © {new Date().getFullYear()} Ready Robotics. All
                Rights Reserved.
              </span>
            </S.LeftText>
          </S.TextWrapper>
          <S.PoweredWrapper>
            <a href="https://aftershock.agency/" target="_blank">
              <S.PoweredByIcon />
            </a>
          </S.PoweredWrapper>
        </S.BottomWrapper>
      </Container>
    </S.Wrapper>
  )
}

export default MobileFooter
