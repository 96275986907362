import styled from "styled-components"
import { Link } from "react-scroll"

export const Wrapper = styled.div`
  background-color: #2d2d33;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em 0;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    display: block;
    overflow: auto;
    white-space: nowrap;
  }
`

export const NavItem = styled(Link)`
  color: rgba(255, 255, 255, 0.5);
  transition: all 0.3s ease;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  padding: 0 1em;
  cursor: pointer;
  &:hover {
    color: white;
  }

  &.active {
    color: white;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    text-align: center;
  }
`
