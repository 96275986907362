import React, { useState } from "react"
import * as S from "./mega-menu.styles.jsx"
import Box from "@mui/material/Box"

const MegaMenu = ({ subItems, sectionTitle }) => {
  const [currentImage, setCurrentImage] = useState(subItems[0].image)

  const changeImage = index => {
    setCurrentImage(subItems[index].image)
  }

  return (
    <Box
      className="megaMenu"
      sx={{
        opacity: "0",
        visibility: "hidden",
        position: "absolute",
      }}
    >
      <S.ItemsContainer>
        {subItems?.map(({ url, title }, index) => (
          <S.Link
            href={url}
            // onMouseEnter={() => changeImage(index)}
            key={`${title}-${index}`}
            // whiteIcon={whiteIcon}
            // blueIcon={blueIcon}
          >
            <S.ItemContainer>
              {/*<S.Icon alt="icon" />*/}
              <span>{title}</span>
            </S.ItemContainer>
          </S.Link>
        ))}
      </S.ItemsContainer>
    </Box>
  )
}
export default MegaMenu
