import React from "react"
import * as S from "./custom-dialog.styles"
import PropTypes from "prop-types"
import CancelIcon from "../../assets/blue-close-icon.svg"

const CustomDialog = ({ title, handleClose, open, description, children }) => {
  return (
    <S.Wrapper onClose={handleClose} open={open}>
      <S.CloseIcon onClick={handleClose}>
        <CancelIcon />
      </S.CloseIcon>
      <div id="formTopWrapper">
        {title && <S.Title>{title}</S.Title>}
        {description && <S.Description>{description}</S.Description>}
      </div>
      {children}
    </S.Wrapper>
  )
}

CustomDialog.propTypes = {
  title: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default CustomDialog
