import styled from "styled-components"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Button from "@mui/material/Button"
import { darken } from "@mui/system"

export const CustomButton = styled(Button)`
  color: white;
  background-color: ${({ theme, color }) =>
    theme.palette[color]
      ? theme.palette[color].main
      : theme.palette.primary.main};
  padding: 0.3em 1.5em;
  border: 1px solid transparent;
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  font-weight: 500 !important;
  border-radius: 20px;
  text-transform: capitalize;

  ${({ theme }) => theme.breakpoints.up("md")} {
    padding: 0.3em 2em;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: 100%;
  }

  &.light {
    background-color: white;
    color: ${({ theme }) => theme.palette.primary.main};
  }

  &.gray {
    background-color: #2f2f2f;
    color: white;
  }

  &.dark-transparent {
    background-color: transparent;
    border: 1px solid white;
    color: white;
    &:hover {
      background-color: white;
      color: black;
    }
  }

  &.blue-transparent-with-arrow {
    border: 1px solid ${({ theme }) => theme.palette.secondary.main};
    color: ${({ theme }) => theme.palette.secondary.main};
    border-radius: 50px;
    &:hover {
      color: white;
      > a {
        span {
          color: white;
        }
        svg {
          //transform: translateX(25%);
          fill: white;
        }
      }
      background-color: ${({ theme }) => theme.palette.secondary.main};
    }
  }

  &.blue-transparent {
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.palette.secondary.main};
    color: ${({ theme }) => theme.palette.secondary.main};
    &:hover {
      color: white;
      background-color: ${({ theme }) => theme.palette.secondary.main};
    }
  }

  &.light-transparent {
    background-color: transparent;
    border: 1px solid #566171;
    color: #566171;
    text-align: center;
    &:hover {
      background-color: #566171;
      color: white;
    }
  }

  &.black-transparent {
    background-color: transparent;
    border: 1px solid black;
    color: black;
    text-align: center;
    &:hover {
      background-color: black;
      color: white;
    }
  }

  &.white-transparent {
    background-color: transparent;
    border: 1px solid white;
    color: white;
    text-align: center;
    &:hover {
      background-color: white;
      color: black;
    }
  }

  &.black {
    background-color: black;
    border: 1px solid black;
    color: white;
  }

  &.blue {
    color: white;
    background-color: ${({ theme }) => theme.palette.secondary.main};
    &:hover {
      background-color: ${({ theme }) => theme.palette.secondary.lightBlue};
    }
  }

  &:not(.light):not(.darkBorder):not(.blue):not(.gray):not(.black):not(.dark-transparent):not(.light-transparent):not(.blue-transparent-with-arrow):not(.white-transparent):not(.black-transparent):not(.blue-transparent):hover {
    background-color: ${({ theme, color }) =>
      darken(
        theme.palette[color]
          ? theme.palette[color].main
          : theme.palette.primary.main,
        0.1
      )};
    //border: 1px solid ${({ theme }) =>
      darken(theme.palette.primary.main, 0.1)};
  }

  &.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
    background: lightgray;
    border-color: lightgray;
    cursor: not-allowed;
  }
`

export const CustomButtonLink = styled(AniLink)`
  color: white;
  background-color: ${({ theme, color }) =>
    theme.palette[color]
      ? theme.palette[color].main
      : theme.palette.primary.main};
  padding: 0.5em 1.5em;
  text-transform: unset;
  border: 1px solid transparent;
  cursor: pointer;
  font-size: ${({ theme }) => theme.typography.pxToRem(14)};
  font-weight: 600;
  border-radius: 5px;
  text-decoration: none;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  min-height: 40px;

  &.light {
    background-color: white;
    color: ${({ theme }) => theme.palette.primary.main};
  }

  &.darkBorder {
    color: ${({ theme }) => theme.palette.primary.main};
    border: 1px ${({ theme }) => theme.palette.primary.main} solid;
    background-color: transparent;

    &:hover {
      background-color: ${({ theme }) => theme.palette.primary.main};
      color: white;
    }
  }

  &.lightBorder {
    color: white;
    border: 1px white solid;
    background-color: transparent;
  }

  &:not(.light):not(.darkBorder):hover {
    background-color: ${({ theme, color }) =>
      darken(
        theme.palette[color]
          ? theme.palette[color].main
          : theme.palette.primary.main,
        0.1
      )};
    //border: 1px solid ${({ theme }) =>
      darken(theme.palette.primary.main, 0.1)};
  }

  &.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
    background: lightgray;
    border-color: lightgray;
    cursor: not-allowed;
  }
`
