import { AppBar, IconButton } from "@mui/material"
import styled from "styled-components"
import CustomLink from "../custom-link/custom-link.component"
import CustomButton from "../custom-button/custom-button.component"

export const CustomAppBar = styled(AppBar)``

export const MainNav = styled.div`
  transition: padding-top 0.3s ease, padding-bottom 0.3s ease,
    background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: white;
  color: white;
  //min-height: 80px;

  // ${({ theme }) => theme.breakpoints.down("sm")} {
  //   min-height: 61px;
  // }
`

export const TopNav = styled.div`
  padding: 0.5em;
  background-color: ${({ theme }) => theme.palette.text.primary};
`

export const InnerMainContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  // ${({ theme }) => theme.breakpoints.up("lg")} {
  //   margin-left: -2em;
  // }
  //
  // ${({ theme }) => theme.breakpoints.down("sm")} {
  //   margin-right: unset;
  // }
  > div {
    //padding: 1.3em 0;
  }
`

export const MainItem = styled.div`
  padding: 1.75em 1.25em 1.75em;
  display: inline-block;
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(20)};
  font-weight: 500;
  box-shadow: inset 0px -2px 0 0 transparent;
  color: black;
  ${({ theme }) => theme.breakpoints.down("md")} {
    padding: 1.2em 0;
  }

  svg {
    max-width: 200px;
  }

  &.logo {
    padding-left: 0;
  }

  .arrow {
    transition: all 0.3s ease;
  }

  .megaMenu {
    transition: all 0.3s ease;
    pointer-events: none;
  }

  &:hover {
    // &.underline {
    //   border-bottom: 8px solid ${({ theme }) =>
      theme.palette.secondary.main};
    // }

    .megaMenu {
      pointer-events: all;
      visibility: visible;
      opacity: 1;
    }

    .arrow {
      transform: rotate(225deg);
      top: 1px;
    }
  }
`

export const Arrow = styled.span`
  ${({ isWhite }) =>
    isWhite
      ? `
    border: solid #0D5C80;
   `
      : `
    border: solid black;
   `};
  margin-left: 0.5em;
  border-width: 0 1px 1px 0;
  display: inline-block;
  position: relative;
  top: -3px;
  padding: 3px;
  transform: rotate(45deg);
  transition: all 0.3s ease;
`

export const MainNavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  //min-height: 80px;
`

export const Button = styled(CustomButton)`
  background-color: black;
  text-transform: none;
  font-size: 1rem;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0.02em;
  line-height: 120%;
  min-height: 45px;
  border: 1px solid white !important;
  margin-left: 0.5em;

  &:hover {
    background-color: white !important;
    color: black !important;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Link = styled(CustomLink)`
  ${({ theme }) => theme.breakpoints.down("lg")} {
    display: none;
  }
`

export const NavWrapper = styled.div`
  .MuiAppBar-colorPrimary {
    background-color: white;
  }
`
export const MenuButton = styled(IconButton)`
  padding-right: 0;
  padding-left: 0;

  svg {
    fill: ${({ theme }) => theme.palette.primary.main};
  }
`
