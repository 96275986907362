import React, { useState } from "react"
import * as S from "./footer-banner.styles"
import { Container, DialogContent, Modal } from "@mui/material"
import CustomButton from "../custom-button/custom-button.component"
import { graphql, useStaticQuery } from "gatsby"
import PartnersForm from "../partners-form/partners-form.component"
import CustomDialog from "../custom-dialog/custom-dialog.component"

const FooterBanner = ({
  title,
  titlePage,
  description,
  buttonText,
  variation = "common",
  hideDescription,
}) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const staticQuery = useStaticQuery(graphql`
    query {
      bannerBg: file(relativePath: { eq: "footer-bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      allWp {
        nodes {
          globalLayouts {
            globalLayouts {
              footerBanner {
                buttonText
                description
                title
                variation
              }
            }
          }
        }
      }
    }
  `)
  const data =
    staticQuery.allWp.nodes[0].globalLayouts.globalLayouts.footerBanner
  return (
    <S.Wrapper color={titlePage === "Forge SDK" ? "#eef7ff" : "#ffffff"}>
      <Container maxWidth="lg">
        <S.VideoWrapper>
          <S.Video
            playsInline
            autoPlay
            loop
            muted
            src="https://admin-rr.aftershock.agency/wp-content/uploads/2022/01/trim-video-light-leak.mov"
          />
          <S.InfoWrapper>
            <S.ContentWrapper>
              {(title || data?.title) && (
                <S.BannerTitle
                  className={hideDescription && "noDescription"}
                  variation={variation ?? data?.variation}
                >
                  {title ?? data?.title}
                </S.BannerTitle>
              )}
              {!hideDescription &&
                (description || data?.description) &&
                variation !== "partners" && (
                  <S.BannerContent>
                    {description ?? data?.description}
                  </S.BannerContent>
                )}

              {variation === "common" ? (
                <S.Button
                  className="blue no-d-block"
                  href={variation === "common" && "/contact/"}
                >
                  {buttonText ?? data?.buttonText}
                </S.Button>
              ) : (
                <S.Button className="blue no-d-block" onClick={handleOpen}>
                  {buttonText ?? data?.buttonText}
                </S.Button>
              )}
            </S.ContentWrapper>
          </S.InfoWrapper>
        </S.VideoWrapper>
      </Container>
      <CustomDialog
        open={open}
        handleClose={handleClose}
        title="Become a Partner"
      >
        <PartnersForm close={handleClose} />
      </CustomDialog>
    </S.Wrapper>
    // <S.GetStartedBanner
    //   color={titlePage === "Forge SDK" ? "#eef7ff" : "#ffffff"}
    // >
    //   <S.Wrapper>
    //     <S.ImageBanner img={staticQuery.bannerBg}>
    //       {title && (
    //         <S.BannerTitle
    //           className={hideDescription && "noDescription"}
    //           variation={variation ?? data?.variation}
    //         >
    //           {title ?? data?.title}
    //         </S.BannerTitle>
    //       )}
    //       {!hideDescription &&
    //         (description || data?.description) &&
    //         variation !== "partners" && (
    //           <S.BannerContent>
    //             {description ?? data?.description}
    //           </S.BannerContent>
    //         )}
    //
    //       {variation === "common" ? (
    //         <S.Button
    //           className="blue no-d-block"
    //           href={variation === "common" && "/contact/"}
    //         >
    //           {buttonText ?? data?.buttonText}
    //         </S.Button>
    //       ) : (
    //         <S.Button className="blue no-d-block" onClick={handleOpen}>
    //           {buttonText ?? data?.buttonText}
    //         </S.Button>
    //       )}
    //     </S.ImageBanner>
    //   </S.Wrapper>
    //   <CustomDialog
    //     open={open}
    //     handleClose={handleClose}
    //     title="Become a Partner"
    //   >
    //     <PartnersForm close={handleClose} />
    //   </CustomDialog>
    // </S.GetStartedBanner>
  )
}

export default FooterBanner
