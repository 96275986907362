import { isBrowser } from "./utils"

export const gtagConversionROICalculatorClick = url => {
  if (isBrowser()) {
    window.gtag("event", "conversion", {
      send_to: "AW-820476121/SSklCIT9ms0BENnxnYcD",
      value: 0.5,
      currency: "USD",
    })
    return false
  }
}

export const gtagConversionROICalculatorComplete = url => {
  if (isBrowser()) {
    window.gtag("event", "conversion", {
      send_to: "AW-820476121/WC9ACMucjM0BENnxnYcD",
      value: 1.0,
      currency: "USD",
      //'event_callback': callback
    })
    return false
  }
}

export const gtagConversionROICalculatorSubmit = url => {
  if (isBrowser()) {
    window.gtag("event", "conversion", {
      send_to: "AW-820476121/UmqCCNCx_MwBENnxnYcD",
      value: 8.0,
      currency: "USD",
      //'event_callback': callback
    })
    return false
  }
}

export const gtagConversionROIContact = url => {
  if (isBrowser()) {
    const callback = function () {
      if (typeof url != "undefined") {
        window.location = url
      }
    }
    window.gtag("event", "conversion", {
      send_to: "AW-820476121/D31GCOmujM0BENnxnYcD",
      value: 5.0,
      currency: "USD",
      event_callback: callback,
    })
    return false
  }
}

export const gtagConversionNewsletterClick = url => {
  if (isBrowser()) {
    window.gtag("event", "conversion", {
      send_to: "AW-820476121/nlIPCM3RuM0BENnxnYcD",
      value: 2.5,
      currency: "USD",
      //'event_callback': callback
    })
    return false
  }
}

export const gtagConversionWhitePaperClick = url => {
  if (isBrowser()) {
    window.gtag("event", "conversion", {
      send_to: "AW-820476121/3Uv3CMXWtswBENnxnYcD",
      value: 0.5,
      currency: "USD",
      //'event_callback': callback
    })
    return false
  }
}

export const gtagConversionWhitePaperDownload = url => {
  if (isBrowser()) {
    window.gtag("event", "conversion", {
      send_to: "AW-820476121/V64cCNHQtswBENnxnYcD",
      value: 5.0,
      currency: "USD",
      //'event_callback': callback
    })
    return false
  }
}

export const gtagConversionContactClick = url => {
  if (isBrowser()) {
    window.gtag("event", "conversion", {
      send_to: "AW-820476121/HhSACLff2sIBENnxnYcD",
    })
    return false
  }
}

export const gtagConversionContactSubmit = url => {
  if (isBrowser()) {
    window.gtag("event", "conversion", {
      send_to: "AW-820476121/V64cCNHQtswBENnxnYcD",
      value: 5,
      currency: "USD",
      //'event_callback': callback
    })
    return false
  }
}
