import styled from "styled-components"
import PoweredBy from "../../assets/powered-by-wht.svg"
import CustomLink from "../custom-link/custom-link.component"
import CustomBgImage from "../custom-bg-image/custom-bg-image.component"
import CustomButton from "../custom-button/custom-button.component"
import { Dialog } from "@mui/material"
import ReadyLogo from "../../assets/ready-robotics-white.svg"

export const Wrapper = styled.footer`
  color: white;
  //padding: 0 0 1em;
  background-color: ${({ theme }) => theme.palette.primary.main};

  ${({ theme }) => theme.breakpoints.down("sm")} {
    .MuiContainer-root {
      padding-right: 2.5em;
      padding-left: 2.5em;
      padding-bottom: 5em;
    }
  }
`

export const BottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    text-align: center;
    flex-direction: column;
    align-items: center;
  }
`

export const LeftText = styled.p`
  color: #888888;
  font-family: ${({ theme }) => theme.fonts.primaryFont};
  font-size: ${({ theme }) => theme.typography.pxToRem(12)};
  letter-spacing: 1px;
  line-height: 24px;
  padding: 1em 0;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    span {
      margin-top: 1.5em;
      display: block;
    }
  }
`

export const PoweredByIcon = styled(PoweredBy)`
  width: 120px;
`

export const Line = styled.div`
  height: 1px;
  background-color: #4e4e4e;
`

export const TopWrapper = styled.div`
  padding: 5em 0;
`

export const Logo = styled.img`
  width: 191px;
  margin-right: 2em;
`

export const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const CustomLinks = styled(CustomLink)`
  color: #ffffff;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(18)};
  letter-spacing: 0;
  line-height: normal;
  font-weight: 300;
  &:not(:last-child) {
    margin-bottom: 1em;
  }
  &.title {
    font-weight: 500;
    margin-bottom: 1em;
  }
`

export const ExternalLink = styled.a`
  color: #ffffff;
  text-decoration: none;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(18)};
  font-weight: 300;
  letter-spacing: 0;
  line-height: 40px;
`

export const FormWrapper = styled.div`
  max-width: 360px;
  margin-top: 2em;
  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin-left: auto;
    margin-top: 0;
  } ;
`

export const NewsLetterTitle = styled.p`
  color: #ffffff;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(18)};
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 0.5em;
`

export const NewsletterContent = styled.div`
  p {
    max-width: 398px;
    width: 100%;
    color: #888888;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-size: ${({ theme }) => theme.typography.pxToRem(16)};
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 0;
  }
`

export const PrivacyLink = styled(CustomLink)`
  text-decoration: underline;
  color: #ffffff;
`

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 2em 0;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    flex-direction: column;
  }
`

export const CustomInput = styled.input`
  width: 100%;
  border-radius: 25px;
  padding: 0.8em 1em;
  background-color: transparent;
  border: 2px solid white;
  color: white;
  margin-right: 1em;
  margin-bottom: 1em;
`
export const ButtonContainer = styled.div`
  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin-top: 1.5rem;
  }
`
export const Button = styled(CustomButton)`
  //padding: 0.5em 1.5em;
  margin: 1.5em 1.5em 0 0;
`

export const CustomDialog = styled(Dialog)`
  .MuiPaper-root {
    overflow-y: unset;
  }
`

export const WhiteLogo = styled(ReadyLogo)`
  width: 190px;
`

export const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
`
