const axios = require("axios")

export const HUBSPOT_API = axios.create({
  baseURL: "https://api.hsforms.com/submissions/v3/integration/submit",
})

export const IPIFY_API = axios.create({
  baseURL: "https://api.ipify.org?format=json",
})

export const gravityFormsApi = axios.create({
  baseURL: `https://admin-rr.aftershock.agency/wp-json/gf/v2/`,
})

export const GREENHOUSE_API = axios.create({
  baseURL: "https://boards-api.greenhouse.io/v1/boards/readyrobotics",
})
export const ZENTITLE_API = axios.create({
  baseURL: "https://my.nalpeiron.com/api/v2/OfflineActivation",
})
