import styled from "styled-components"
import { Dialog, IconButton, Typography } from "@mui/material"

export const Wrapper = styled(Dialog)`
  .MuiPaper-root {
    max-width: 400px;
    padding: 1em;
    background-color: white;
    position: relative;
    overflow: unset;
  }
`

export const CloseIcon = styled(IconButton)`
  position: absolute;
  top: -16px;
  right: -16px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  padding: 0;
  svg {
    width: 100%;
    height: 100%;
  }
`

export const Title = styled.h2`
  color: #09090b;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 36px;
  margin-bottom: 0.5em;
`

export const Description = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.content};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  letter-spacing: 0;
  line-height: 24px;
  padding: 1em 0;
`
