import React, { useState } from "react"
import * as S from "./header.styles.jsx"
import { Container, useScrollTrigger } from "@mui/material"
import headerItems from "./header.json"
import Logo from "../../assets/ready-robotics-logo-white.svg"
import CloseIcon from "@mui/icons-material/Close"
import MenuIcon from "@mui/icons-material/Menu"
import Box from "@mui/material/Box"
import { cleanBlankCharacter } from "../../utils"
import CustomButton from "../custom-button/custom-button.component"
import MegaMenu from "./mega-menu/mega-menu.component"
import BlackNav from "./black-nav/black-nav.component"
import CustomLink from "../custom-link/custom-link.component"
import HeaderMobile from "./header-mobile/header-mobile.component"

const Header = ({ haveBlackNav }) => {
  const [isActiveMenu, setIsActiveMenu] = useState(false)

  const scrollTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  })

  const handleToggleMenu = () => {
    setIsActiveMenu(!isActiveMenu)
  }

  return (
    <S.NavWrapper>
      <S.CustomAppBar
        id="mainNav"
        position="fixed"
        elevation={scrollTrigger ? 4 : 0}
      >
        <Container maxWidth="xl">
          <S.MainNav>
            <S.MainNavContainer>
              <S.MainItem url="/" className="logo">
                <CustomLink url="/">
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <Logo />
                  </Box>
                </CustomLink>
              </S.MainItem>
              <S.InnerMainContainer>
                <Box sx={{ display: { xs: "none", lg: "block" } }}>
                  {headerItems.mainItems.map(
                    (
                      { url, sectionTitle, items, removeTopLine, target },
                      index
                    ) => (
                      <S.MainItem
                        removeTopLine={removeTopLine}
                        key={`title-${index}`}
                        className={items && "underline"}
                      >
                        <CustomLink url={url} target={target && target}>
                          {sectionTitle}
                        </CustomLink>
                        {items && (
                          <>
                            <S.Arrow className="arrow" />
                            <MegaMenu
                              subItems={items}
                              sectionTitle={sectionTitle}
                            />
                          </>
                        )}
                      </S.MainItem>
                    )
                  )}
                </Box>
              </S.InnerMainContainer>
              <S.ButtonWrapper>
                <S.Link url="/forge-os-experience/">
                  <CustomButton className="blue">
                    {cleanBlankCharacter("Experience Forge/OS")}
                  </CustomButton>
                </S.Link>
                <Box sx={{ display: { lg: "none", xs: "block" } }}>
                  <S.MenuButton onClick={handleToggleMenu}>
                    {isActiveMenu ? <CloseIcon /> : <MenuIcon />}
                  </S.MenuButton>
                </Box>
              </S.ButtonWrapper>
            </S.MainNavContainer>
          </S.MainNav>
        </Container>
        {haveBlackNav && <BlackNav />}
      </S.CustomAppBar>

      <HeaderMobile
        isActiveMenu={isActiveMenu}
        handleClose={() => setIsActiveMenu(false)}
        items={headerItems.mainItems}
      />
    </S.NavWrapper>
  )
}
export default Header
