import styled from "styled-components"
import { Accordion } from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import CustomLink from "../custom-link/custom-link.component"
import CustomButton from "../custom-button/custom-button.component"
import PoweredBy from "../../assets/powered-by-wht.svg"

export const Wrapper = styled.footer`
  color: white;
  padding: 3em 0 0;
  background-color: ${({ theme }) => theme.palette.primary.main};

  ${({ theme }) => theme.breakpoints.down("sm")} {
    .MuiContainer-root {
      padding: 0 1em 0;
    }
  }
`
export const Logo = styled.img`
  width: 200px;
  height: 45.7px;
  margin-right: 2em;
`

export const AccordionWrapper = styled.div`
  padding: 2em 0;
`

export const CustomAccordion = styled(Accordion)`
  background-color: ${({ theme }) => theme.palette.primary.main};
`

export const ExpandIcon = styled(ExpandMoreIcon)`
  fill: white;
`

export const AccordionTitle = styled.h3`
  color: #ffffff;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(24)};
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
`

export const MobileLink = styled(CustomLink)`
  color: #ababab;
  font-size: ${({ theme }) => theme.typography.pxToRem(20)};
  letter-spacing: 0;
  line-height: 43px;
`

export const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const SocialWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const ExternalLink = styled.a`
  color: #ababab;
  font-size: ${({ theme }) => theme.typography.pxToRem(20)};
  letter-spacing: 0;
  line-height: 43px;
  text-decoration: none;
`

export const FormWrapper = styled.div`
  max-width: 360px;
  margin-top: 2em;
  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin-left: auto;
    margin-top: 0;
  } ;
`

export const NewsLetterTitle = styled.h2`
  color: #ffffff;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(44)};
  font-weight: 500;
  letter-spacing: 0;
  line-height: 37px;
  margin-bottom: 0;
`

export const NewsletterContent = styled.div`
  p {
    max-width: 398px;
    width: 100%;
    color: white;
    opacity: 0.5;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-size: ${({ theme }) => theme.typography.pxToRem(16)};
    letter-spacing: 0;
    line-height: 30px;
    margin-bottom: 0;
  }
`

export const ButtonContainer = styled.div``

export const Button = styled(CustomButton)`
  //padding: 0.5em 1.5em;
  margin: 1.5em 0;
`

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(151, 151, 151, 0.5);
`

export const BottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5em;
`

export const LeftText = styled.p`
  color: #888888;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 24px;
  padding: 1em 0;
`

export const PoweredByIcon = styled(PoweredBy)`
  width: 120px;
  margin-left: auto;
`

export const PoweredWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
`
