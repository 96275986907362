/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import SEO from "../components/seo/seo.component"
import Header from "../components/header/header.component"
import { AppContainer } from "../styles/app.styles"
import Footer from "./footer/footer.component"

const Layout = ({
  seo,
  children,
  pageTitle,
  haveBlackNav,
  hideFooterBanner,
  hideDescription,
  footerBanner,
}) => {
  return (
    <>
      {seo && <SEO data={seo} />}
      <Header haveBlackNav={haveBlackNav} />
      <AppContainer id="mainWrapper">{children}</AppContainer>
      <Footer
        hideDescription={hideDescription}
        footerBanner={footerBanner}
        hideFooterBanner={hideFooterBanner}
        titlePage={pageTitle}
      />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
